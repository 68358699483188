@keyframes fadeIn
  0%
    opacity: 0
  100%
    opacity: 1

@keyframes fadeOut
  0%
    opacity: 1
  100%
    opacity: 0

[class^="userway-"] .Toastify__toast-container *
  animation: fadeIn 5s, fadeOut 0s 0s !important